














































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Project } from '@/model/Project'
import utils from '../../../utils/Utils'
import * as FileSaver from 'file-saver'

export interface CustomFile {
  base64: string
  extention: string
}

const defaultProject: Project = {
  id: 0,
  name: '',
}

@Component({})
export default class CardProject extends Vue {
  @Prop({ default: defaultProject }) project!: Project

  get imageTexte() {
    if (this.src) {
      return this.$t('editImage')
    } else {
      return this.$t('addImage')
    }
  }

  get isAdmin() {
    return this.$store.getters.isAdmin
  }

  get canEditImage(): boolean {
    return true
  }

  get address(): string {
    if (!this.project.address && !this.project.city && !this.project.postalCode)
      return this.$t('noAddress').toString()

    return `${this.project.address || ''} - ${this.project.postalCode || ''} - `
  }

  get city(): string {
    if (!this.project.address && !this.project.city && !this.project.postalCode)
      return ''

    return `${this.project.city || ''}`
  }

  src = ''
  modifier = 'Modifier'
  currentYear = +utils.UTCToYear(utils.nowToUTC())

  created() {
    this.$store.dispatch('getProjectImage', this.project.id).then(json => {
      this.src = json.data
    })
  }

  async generateAccounting(year: number) {
    await this.$store.dispatch('getAccountingByYear', {
      projectId: this.project.id,
      year: year,
    })
    await this.$store.dispatch('getUserProject', this.project.id)
    const accounting = this.$store.getters.accounting
    const user = this.$store.getters.user

    this.$store
      .dispatch('generateAccountingReport', accounting.id)
      .then(json => {
        this.$store.dispatch('toaster/toast', json)
        if (json.success) {
          const today = new Date()
          const filename = `${today.getFullYear()}-${today.getMonth() +
            1}-${today.getDate()}_${user.username}_Écriture comptable_${year}`
          FileSaver.saveAs(json.data, `${filename}.xlsx`)
        }
      })
  }

  goToProject() {
    this.$router.push({
      path: `/project/${this.project.id}`,
      params: { id: '' + this.project.id },
    })
  }

  goToLetter() {
    this.$router.push({
      path: `/project/${this.project.id}/letter`,
      params: { id: '' + this.project.id },
    })
  }

  goToAccounting() {
    this.$router.push({
      path: `/project/${this.project.id}/accounting`,
      params: { id: '' + this.project.id },
    })
  }

  saveProjectImage(file: CustomFile) {
    this.$store
      .dispatch('addProjectImage', {
        base64: file.base64,
        extention: file.extention,
        projectId: this.project.id,
      })
      .then(json => {
        if (json.success) this.src = json.data
        this.$store.dispatch('toaster/toast', json, { root: false })
      })
  }
}
