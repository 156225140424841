


























import { Component, Vue } from 'vue-property-decorator'
import CardProject from '../../components/core/project/CardProject.vue'
import { PROJECT_STATES, Project } from '@/model/Project'
import router from '@/router'

@Component({
  components: { CardProject },
})
export default class ViewProjects extends Vue {
  get projects(): Project[] {
    return this.$store.getters.projects
  }

  get atLeastOneEnabled(): boolean {
    let atLeastOneEnabled = false
    for (const p of this.projects) {
      if (p && p.name) {
        if (p.state === PROJECT_STATES.ENABLED) {
          atLeastOneEnabled = true
        }
      }
    }
    return atLeastOneEnabled
  }

  goToAddNewProject() {
    router.push('new-project')
  }
}
